import React from "react";
import Seo from "../../components/Seo";
import Layout from "../../components/layout";
import InstagramFeed from "../../components/InstagramFeed";
import OurLocations from "../../sections/OurLocations";
import { CheckCircleIcon } from "@heroicons/react/outline";

const SubmittedPage = () => {
  return (
    <Layout>
      <Seo
        title="Submitted | FEAT Fitness"
        meta_title="Submitted | FEAT Fitness"
        meta_desc="We have your form submitted"
        slug="pre-training-questionnaire/submitted"
      />
      <main>
        <div>
          <div className="bg-feat-lightgrey">
            <div className="max-w-lg mx-auto py-24 px-6 text-base text-feat-darkgray-500 text-center">
              <CheckCircleIcon
                className="w-32 h-32 text-green-500 m-auto mb-8"
                aria-hidden="true"
              />
              Amazing! We have the information we need. If we haven't been in
              touch yet, we'll make sure we touch base shortly to get you
              training. See you in the park!
            </div>
          </div>
          <OurLocations />

          {/* Instagram Feed */}
          <InstagramFeed />
          {/* End Instagram Feed */}
        </div>
      </main>
    </Layout>
  );
};

export default SubmittedPage;
